import MainNav from "./Components/main-nav.js";
import SearchOverlay from "./Components/search-overlay.js";
import Accordion from "./Components/accordion.js";
import MapFilter from "./Components/map-filter.js";
import PostFilter from "./Components/posts-filter.js";
import BannerCarousel from "./Components/banner-carousel.js";
import LinkList from "./Components/link-list";
import Marketing from "./Components/marketing.js";

import Prism from "./Vendors/prism.js";

window.components = {};

const init = {
};

for (var i in init) {
  const component = new init[i]();
  window.components[i] = component;
}

document.dispatchEvent(new Event("components_init"));
