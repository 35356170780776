const listItems = document.querySelectorAll(".category-button");
const allPosts = document.querySelectorAll(".blog-landing-page__item");

function toggleActiveClass(active) {
  listItems.forEach((item) => {
    item.classList.remove("active");
  });
  active.classList.add("active");
}

function togglePosts(dataClass) {
  if (dataClass === "all") {
    for (let i = 0; i < allPosts.length; i++) {
      allPosts[i].style.display = "block";
    }
  } else {
    for (let i = 0; i < allPosts.length; i++)
      allPosts[i].dataset.class === dataClass
        ? (allPosts[i].style.display = "block")
        : (allPosts[i].style.display = "none");
  }
}

for (let i = 0; i < listItems.length; i++) {
  listItems[i].addEventListener("click", function () {
    toggleActiveClass(listItems[i]);
    togglePosts(listItems[i].dataset.class);
  });
}
