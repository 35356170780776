const listItems = document.querySelectorAll(".category-button");
const allMaps = document.querySelectorAll(".contact__map");

function removeActiveClass(){
  listItems.forEach((item) => {
    item.classList.remove("active");
  });
}

function hideAllMaps () {
    allMaps.forEach((item) => {
      item.style.display = 'none';
    });
}

function toggleActiveClass(active) {
  removeActiveClass();
  active.classList.add("active");
}

function toggleMaps(dataClass) {
  for (let i = 0; i < allMaps.length; i++)
    allMaps[i].dataset.class === dataClass
      ? (allMaps[i].style.display = "block")
      : (allMaps[i].style.display = "none");
}

const queryString = window.location.search;
const sydneyButton = document.querySelector("a[data-class='sydney']");
const melbourneButton = document.querySelector("a[data-class='melbourne']");
const aucklandButton = document.querySelector("a[data-class='auckland']");

const melbourneMap = document.querySelector("div[data-class='melbourne']");
const aucklandMap = document.querySelector("div[data-class='auckland']");

for (let i = 0; i < listItems.length; i++) {
  listItems[i].addEventListener("click", function () {
    toggleActiveClass(listItems[i]);
    toggleMaps(listItems[i].dataset.class);
  });
  if(queryString == "?map=melbourne") {
    removeActiveClass();
    hideAllMaps();
    melbourneButton.classList.add("active");
    melbourneMap.style.display = "block";
  }
  if(queryString == "?map=auckland") {
    removeActiveClass();
    hideAllMaps();
    aucklandButton.classList.add("active");
    aucklandMap.style.display = "block";
  }
}