import $ from "jquery";
import "slick-carousel";

//Single Item
$(".carousel-wrapper").slick({
  infinite: true,
  arrows: true,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false,
      },
    },
  ],
});
