var tabRadios = document.getElementsByName("service-tab");
var setCheck;
var x = 0;
for (x = 0; x < tabRadios.length; x++) {
  tabRadios[x].onclick = function () {
    if (setCheck != this) {
      setCheck = this;
    } else {
      this.checked = false;
      setCheck = null;
    }
  };
}

const listItems = document.querySelectorAll(".accordion__label");
const allTabs = document.querySelectorAll(".services-accordion__img");

function toggleActiveClass(active) {
  const isActive = active.classList.contains("active");
  listItems.forEach((item) => {
    item.classList.remove("active");
  });
  if(!isActive)
   active.classList.add("active");
}

function toggleMaps(dataClass) {
  for (let i = 0; i < allTabs.length; i++)
    allTabs[i].dataset.class === dataClass
      ? (allTabs[i].style.display = "block")
      : (allTabs[i].style.display = "none");
}

for (let i = 0; i < listItems.length; i++) {
  listItems[i].addEventListener("click", function () {
    toggleActiveClass(listItems[i]);
    toggleMaps(listItems[i].dataset.class);
  });
}
