import $ from "jquery";

const mainNav = $(".main-nav__menu");
const menuExtended = $(".menu-extended");
const menuItems = $(".menu-extended__primary .menu-extended__primary-li");
const menuPrimaryItem = ".menu-extended__primary-item";
const menuChildItem = ".menu-extended__secondary";
const chevronIcon = ".menu-extended__primary-chevron";

mainNav.on( "click", function(){
    menuExtended.slideToggle();
    mainNav.toggleClass("open");
    if(mainNav.hasClass("open")){
        $("body").css({"overflow": "hidden"});
    }
    else { 
        $("body").css({"overflow": "auto"});  
      }
});
$(".main-nav__menu").on({
    mouseenter: function(){
        $(".main-nav__menu").css('background-image','url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4zLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNyAzNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzcgMzc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGREMxMzE7fQ0KPC9zdHlsZT4NCjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMzEuOTk3KSI+DQoJPHJlY3QgeT0iMCIgY2xhc3M9InN0MCIgd2lkdGg9IjM3IiBoZWlnaHQ9IjUiLz4NCjwvZz4NCjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTUuOTk5KSI+DQoJPHJlY3QgeT0iMCIgY2xhc3M9InN0MCIgd2lkdGg9IjM3IiBoZWlnaHQ9IjUiLz4NCjwvZz4NCjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPg0KCTxyZWN0IGNsYXNzPSJzdDAiIHdpZHRoPSIzNyIgaGVpZ2h0PSI1Ii8+DQo8L2c+DQo8L3N2Zz4NCg==)');
    },
    mouseleave: function () {
        $(".main-nav__menu").css('background-image','url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNyIgaGVpZ2h0PSIzNyIgdmlld0JveD0iMCAwIDM3IDM3Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDMxLjk5NykiPjxyZWN0IGNsYXNzPSJhIiB3aWR0aD0iMzciIGhlaWdodD0iNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjAwMykiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxNS45OTkpIj48cmVjdCBjbGFzcz0iYSIgd2lkdGg9IjM3IiBoZWlnaHQ9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDEpIi8+PC9nPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPjxyZWN0IGNsYXNzPSJhIiB3aWR0aD0iMzciIGhlaWdodD0iNSIvPjwvZz48L3N2Zz4=)');
    },
    click: function () {
        $(".main-nav__menu").css('background-image','url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNyIgaGVpZ2h0PSIzNyIgdmlld0JveD0iMCAwIDM3IDM3Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDMxLjk5NykiPjxyZWN0IGNsYXNzPSJhIiB3aWR0aD0iMzciIGhlaWdodD0iNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjAwMykiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxNS45OTkpIj48cmVjdCBjbGFzcz0iYSIgd2lkdGg9IjM3IiBoZWlnaHQ9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDEpIi8+PC9nPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPjxyZWN0IGNsYXNzPSJhIiB3aWR0aD0iMzciIGhlaWdodD0iNSIvPjwvZz48L3N2Zz4=)');
    }
});

menuItems.each(function(){
    $(this).on("mouseover", function(e){
        if ($(e.target).hasClass("menu-extended__primary-item")) {
            $(this).find(menuChildItem).addClass("open");
        }    
    });
    $(this).on("mouseleave", function(){
        $(this).find(menuChildItem).removeClass("open");
        $(menuPrimaryItem).removeClass("touch-hover");
    });
    $(this).find(chevronIcon).on("click", function(){
        if (window.innerWidth <= 990 ) {   
            $(menuChildItem).slideUp();
        }
        $(menuChildItem).removeClass("open");
        $(chevronIcon).removeClass("open");
        $(menuPrimaryItem).removeClass("touch-hover");
    });

    $(this).on('touchstart', function (e) {
        var dropDown = $(this).closest(menuItems).find(menuChildItem);
        if (!$(this).find(menuChildItem).hasClass("open")) {

            $(menuChildItem).removeClass("open");
            $(chevronIcon).removeClass("open");
            $(menuPrimaryItem).removeClass("touch-hover");
            if (window.innerWidth <= 990 ) {   
                $(this).closest('.menu-extended__primary').find(menuChildItem).not(dropDown).slideUp();
                $(this).find(menuChildItem).slideDown();
            }
            $(this).find(menuChildItem).addClass("open");
            $(this).find(menuPrimaryItem).addClass("touch-hover");
            $(this).find($(chevronIcon)).addClass("open");
            e.preventDefault();
        }
    })
});