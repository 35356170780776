import $ from "jquery";

$('.marketing__redirect-cookie').click(function() {
    let cookieName = $('.marketing__cookie-name').data('cookie-name');
    setCookie(cookieName, 'cookieValue', 7);
});

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function checkURLForRedirect() {
    if (window.location.href.indexOf('/redirect?cookieName') !== -1) {
        const urlParams = new URLSearchParams(window.location.search);
        const cookieName = urlParams.get('cookieName');
        const urlRedirect = urlParams.get('url');

        setCookie(cookieName, '', 7);
        
        window.location.href = urlRedirect;
    }
}

window.onload = function() {
    checkURLForRedirect();
};