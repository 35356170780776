const searchIcon = document.querySelector(".main-nav__search");
const greyOverlay = document.querySelector(".search-overlay");
const searchBar = document.querySelector(".search-overlay__bar");

const addClassOpen = () => searchIcon.classList.add("open");
const removeClassOpen = () => searchIcon.classList.remove("open");

function disableScrolling() {
  var x = window.scrollX;
  var y = window.scrollY;
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
}

function enableScrolling() {
  window.onscroll = function () {};
}

searchIcon.addEventListener("click", function () {
  if (greyOverlay.style.display == "none") {
    greyOverlay.style.display = "block";
    addClassOpen();
    disableScrolling();
  } else {
    greyOverlay.style.display = "none";
    removeClassOpen();
    enableScrolling();
  }
});
// let scrollpos = window.scrollY;
// window.addEventListener("scroll", function () {
//   scrollpos = window.scrollY;

//   if (scrollpos > 1) {
//     removeClassOpen();
//     greyOverlay.style.display = "none";
//   }
//   console.log(scrollpos);
// });
